<template>
  <v-card>
    <v-card-title class="grey lighten-2">
      Kies een type voor de routing.
    </v-card-title>
    <v-card-text>
      <v-row class="mt-2">
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          v-for="type in board.routingTypes"
          :key="type.id"
        >
          <v-card
            class="grey lighten-5 clickable"
            @click="chooseThisType(type)"
            height="100%"
          >
            <v-card-title>
              {{ type.name }} ({{ type.shortName }})
              <v-spacer />
              <v-icon v-if="job">{{
                routingTypeIsUsed(type.shortName)
                  ? "mdi-checkbox-marked-circle-outline"
                  : "mdi-checkbox-blank-circle-outline"
              }}</v-icon>
            </v-card-title>
            <!-- <v-card-actions>
              <v-btn width="100%" @click="chooseThisType(type)">
                Kies dit type
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      board: (state) => state.board,
      job: (state) => state.board.job,
    }),
  },
  methods: {
    routingTypeIsUsed(routingTypeShortName) {
      if (this.board.job != undefined) {
        for (const routing of this.board.job.routings) {
          if (routing.routingType.shortName == routingTypeShortName) {
            return true;
          }
        }
      }
      return false;
    },
    chooseThisType(type) {
      this.$emit("chooseThisType", type);
    },
  },
};
</script>

<style scoped></style>
