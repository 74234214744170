<template>
  <v-container v-if="!jobIsEmpty">
    <JobHeader />
    <v-row>
      <v-col>
        <RoutingType v-on:chooseThisType="chooseThisType" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import JobHeader from "@/components/JobHeader";
import RoutingType from "@/components/RoutingType";
import services from "../../services/services.js";

export default {
  components: { JobHeader, RoutingType },
  beforeCreate() {
    if (this.$store.getters.jobIsEmpty) {
      this.$router.push({ name: "start" });
    }
  },
  computed: {
    ...mapState({
      board: (state) => state.board,
      job: (state) => state.board.job,
    }),
    jobIsEmpty() {
      return this.$store.getters.jobIsEmpty;
    },
  },
  methods: {
    routingTypeIsUsed(routingTypeShortName) {
      for (const routing of this.board.job.routings) {
        if (routing.routingType.shortName == routingTypeShortName) {
          return true;
        }
      }
      return false;
    },
    chooseThisType(type) {
      this.board.columns[1].routingTypeId = type.id;
      this.board.job.routingType = type;
      for (const routing of this.board.job.routings) {
        // console.log(routing);
        if (routing.routingType.shortName == type.shortName) {
          if (
            routing.routingType.id == "00000000-0000-0000-0000-000000000000"
          ) {
            routing.routingType.id = type.id;
            routing.routingTypeId = type.id;
          }
          this.chooseThisRouting(routing);
          return;
        }
      }
      this.$router.push({ name: "useRoutingTemplate" });
    },
    chooseThisRouting(routing) {
      if (routing.id != "00000000-0000-0000-0000-000000000000") {
        services
          .getRouting(routing.id)
          .then((response) => {
            // console.log(response);
            this.$store.commit("PREPARE_SET_ROUTING", {
              response,
            });
            this.$router.push({ name: "board" });
          })
          .catch((error) => {
            this.$store.commit("SAVE_ERROR", { error });
            console.log("There are errors: ", error);
          });
      } else if (routing.routingSteps.length > 0) {
        if (!routing.name) routing.name = "DEFAULT";
        let response = {
          data: routing,
        };
        this.$store.commit("PREPARE_SET_ROUTING", {
          response,
        });
        this.$router.push({ name: "board" });
      }
    },
  },
};
</script>

<style scoped></style>
